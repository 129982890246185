import React, { ReactNode, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import Welcome from './pages/Welcome';
import Profile from './pages/profile';
import RouterScan from './pages/routerScan';
import NetworkScan from './pages/NetworkScan';
import TestComplete from './pages/TestComplete';
import { Question1, Question2 } from './pages/questionnaires';
import ResultsOverviewPage from './pages/ResultsOverview';

import { RoutePath } from './RoutePath';
import RouterSpeedTestQuestion from './pages/routerSpeedTestQuestion';
import RouterSpeedTestIntroduction from './pages/routerSpeedTestIntroduction';
import WifiLoading from 'pages/WifiLoading';
import { usePageState } from 'hooks/usePageState';
import { useFlow } from 'hooks/useFlow';
import { useResetRecoilState } from 'recoil';
import { pageState } from 'states/atoms';

const privateRoute = (child: ReactNode) => (
  <ProtectedRoute>{child}</ProtectedRoute>
);

const AppRoutes = () => {
  const { page } = usePageState();
  const { goTo } = useFlow();
  const resetPageState = useResetRecoilState(pageState);

  /// Redirect to welcome page and clean pageState if no data in networkState on load...
  useEffect(() => {
    const { downloadSpeed, uploadSpeed } = page.networkState;
    if (!(Boolean(downloadSpeed) && Boolean(uploadSpeed))) {
      goTo(RoutePath.Welcome);
      resetPageState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path={RoutePath.Home}
        element={<Navigate to="/welcome" replace />}
      />
      <Route path={RoutePath.Welcome} element={<Welcome />} />
      <Route path={RoutePath.NetworkTest} element={<NetworkScan />} />
      <Route path={RoutePath.TestComplete} element={<TestComplete />} />
      <Route
        path={RoutePath.RouterSpeedQ1}
        element={<RouterSpeedTestQuestion />}
      />
      <Route path={RoutePath.Profile} element={privateRoute(<Profile />)} />
      <Route
        path={RoutePath.RouterSpeedStart}
        element={<RouterSpeedTestIntroduction />}
      />
      <Route path={RoutePath.RouterSpeedProgress} element={<RouterScan />} />
      <Route path={RoutePath.RouterSpeedCompleted} element={<RouterScan />} />
      <Route path={RoutePath.OnlineActivitiesQ1} element={<Question1 />} />
      <Route path={RoutePath.WifiQ2} element={<Question2 />} />
      <Route path={RoutePath.WifiLoading} element={<WifiLoading />} />
      <Route path={RoutePath.Results} element={<ResultsOverviewPage />} />
    </Routes>
  );
};

export default AppRoutes;
