import { atom } from 'recoil';
import { sessionStorageEffect } from '../effects/sessionStorageEffect';

export type NetworkPageState = {
  downloadSpeed: string;
  uploadSpeed: string;
  capabilities?: Record<string, string>;
};

export type RouterPageState = {
  downloadSpeed: string;
};

export type QuestionPageState = {
  q1: string[];
  q2: string[];
};

export type PageState = {
  networkState: NetworkPageState;
  // if true routerState must have a `downloadSpeed` value
  isRouterTest: boolean | null;
  routerState: RouterPageState | null;
  questionState: QuestionPageState;
};

// TODO: Create effect to store the state to localStorage | sessionStorge | etc..
export const pageState = atom<PageState>({
  key: 'pageState',
  default: {
    networkState: {
      downloadSpeed: '',
      uploadSpeed: '',
    },
    isRouterTest: null,
    routerState: null,
    questionState: {
      q1: [],
      q2: [],
    },
  },
  effects: [sessionStorageEffect('pageState')],
});
