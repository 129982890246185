import React, { FC } from 'react';
import { Welcome } from 'components/PageHeader/Welcome';
import styled from 'styled-components';
import RouterSpeedTestContainer from './components/RouterSpeedTestContainer';

const PageContainer = styled.div`
  height: auto;
`;

type RouterSpeedTestProps = {
  downloadSpeed: string;
  progress: number;
};

const RouterSpeedTest: FC<RouterSpeedTestProps> = ({
  downloadSpeed,
  progress,
}) => {
  const isSpeedTestDone = progress === 100;

  return (
    <PageContainer data-testid="RouterPage_Container">
      {isSpeedTestDone ? (
        <Welcome
          pageTitle="Second test complete – one more step"
          pageSubTitle="Select “Retest” to start this test again."
        />
      ) : (
        <Welcome
          pageTitle="Running the router test now..."
          pageSubTitle="Try not to move. The test may take up to 2 minutes."
        />
      )}
      <RouterSpeedTestContainer
        progress={progress}
        downloadSpeed={downloadSpeed}
      />

      {/* comment out for scroll testing */}
      {/* {Array.from(Array(100).keys()).map((i) => (
        <p key={i}>{i}</p>
      ))} */}
    </PageContainer>
  );
};

export default RouterSpeedTest;
