import React, { VoidFunctionComponent } from 'react';
import { Container } from 'components/Main';
import styled from 'styled-components';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { updateHeader } from '@soluto-private/mx-app-header';
import { useNavigate } from 'react-router-dom';
import RouterSpeedTestIntroductionPage from './routerSpeedTestIntroductionPage';
import { RoutePath } from 'RoutePath';
import { usePageState } from 'hooks/usePageState';

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const RouterSpeedTestIntroduction: VoidFunctionComponent = () => {
  const navigate = useNavigate();
  const { setIsRouterTest } = usePageState();

  updateHeader({
    view: 'service-view',
    menu: true,
    onBack: () => navigate(RoutePath.RouterSpeedQ1),
    subNavConfig: {
      showBackButton: true,
      showPartnerLogo: true,
    },
  });

  const onSkip = () => {
    navigate(RoutePath.OnlineActivitiesQ1);
    setIsRouterTest(false);
  };

  const buttonRightGroup = (
    <>
      <Button onClick={onSkip} variant="outline" color="secondary">
        Skip
      </Button>
      <Button
        onClick={() => navigate(RoutePath.RouterSpeedProgress)}
        color="secondary"
      >
        Start Test
      </Button>
    </>
  );

  return (
    <MainContainer>
      <Container
        currentStep={2}
        footer={{
          onBack: () => navigate(RoutePath.RouterSpeedQ1),
          buttonRightGroup,
        }}
      >
        <RouterSpeedTestIntroductionPage />
      </Container>
    </MainContainer>
  );
};

export default RouterSpeedTestIntroduction;
