import React, { VoidFunctionComponent, useEffect } from 'react';
import { Container } from 'components/Main';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import RouterSpeedTest from './routerSpeedTest';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { usePageState } from 'hooks/usePageState';
import { useFlow } from 'hooks/useFlow';
import { RoutePath } from 'RoutePath';
import { useLocation } from 'react-router';
import { useRandomizedProgress } from 'hooks/useRandomizedProgress';
import { useRouterScan } from 'hooks/useRouterScan';

const MainContainer = styled.div`
  height: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative;
`;

const useScan = () => {
  const { goTo } = useFlow();
  const location = useLocation();
  const { startScan, cancelScan, scanState } = useRouterScan();
  const { page } = usePageState();

  const isScanCompleted = (() => {
    const isPageComplete = RoutePath.RouterSpeedCompleted === location.pathname;
    if (isPageComplete) {
      return Boolean(page.routerState);
    }
    return scanState === SpeedTestStatus.completed;
  })();

  const { progress, resetProgress } = useRandomizedProgress(isScanCompleted);

  /// Redirect to RoutePath.RouterSpeedCompleted if scan completed
  useEffect(() => {
    if (isScanCompleted) {
      goTo(RoutePath.RouterSpeedCompleted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanCompleted]);

  /// Return to RoutePath.RouterSpeedProgress if page.routerState === null
  useEffect(() => {
    const isPageComplete = RoutePath.RouterSpeedCompleted === location.pathname;
    if (!page.routerState && isPageComplete) {
      goTo(RoutePath.RouterSpeedProgress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, page.routerState]);

  useEffect(() => {
    const isPage = location.pathname === RoutePath.RouterSpeedProgress;
    if (isPage) {
      cancelScan();
      startScan();
      resetProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return {
    startScan,
    cancelScan,
    isScanCompleted,
    downloadSpeed: page.routerState?.downloadSpeed || '',
    progress,
  };
};

const RouterScan: VoidFunctionComponent = () => {
  const { goTo } = useFlow();
  const { cancelScan, isScanCompleted, downloadSpeed, progress } = useScan();

  const buttonRightGroup = isScanCompleted ? (
    <>
      <Button
        onClick={() => goTo(RoutePath.RouterSpeedProgress)}
        variant="outline"
        color="secondary"
      >
        Retest
      </Button>
      <Button
        onClick={() => {
          goTo(RoutePath.OnlineActivitiesQ1);
        }}
        color="secondary"
      >
        Continue
      </Button>
    </>
  ) : (
    <Button
      onClick={() => {
        cancelScan();
        goTo(RoutePath.RouterSpeedStart);
      }}
      variant="outline"
      color="secondary"
    >
      Cancel
    </Button>
  );

  return (
    <MainContainer>
      <Container
        currentStep={2}
        footer={{
          onBack: () => goTo(RoutePath.RouterSpeedStart),
          buttonRightGroup,
        }}
      >
        <RouterSpeedTest progress={progress} downloadSpeed={downloadSpeed} />
      </Container>
    </MainContainer>
  );
};

export default RouterScan;
