import { SpeedTestDelegate, WixiSnap } from '@soluto-private/wixi-web-sdk';
import { usePageState } from './usePageState';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { routerState } from 'states/atoms/routerState';

export const useRouterScan = () => {
  const { setRouterState } = usePageState();
  const [routerScan, setRouterScan] = useRecoilState(routerState);
  const resetState = useResetRecoilState(routerState);

  const startScan = async () => {
    const wixiSnap = new WixiSnap();

    setRouterScan((rscan) => ({ ...rscan, wixiSnap }));

    SpeedTestDelegate.onDownloadSpeedChange = (speed) => {
      console.log('download speed: ', speed);
      setRouterState({ downloadSpeed: speed });
    };

    SpeedTestDelegate.onStatusChange = (status) => {
      setRouterScan((rscan) => ({
        ...rscan,
        scanState: status,
      }));
    };

    await wixiSnap.snap();
  };

  return {
    startScan: () => {
      setTimeout(() => startScan(), 0);
    },
    scanState: routerScan.scanState,
    cancelScan: () => {
      resetState();
      routerScan.wixiSnap?.cancel();
      setRouterState(null);
    },
  };
};
